import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Layout"
import BodyBlocks from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BodyBlocks"
import ProductHeader from "gatsby-theme-rothenberger/src/components/blocks/ProductHeader"
import DividerBlock from "../../themes/gatsby-theme-rothenberger/src/components/blocks/DividerBlock"
import VariantCardsBlock from "../../themes/gatsby-theme-rothenberger/src/components/blocks/VariantCardsBlock"
import ProductTabsBlock from "gatsby-theme-rothenberger/src/components/blocks/ProductTabsBlock"

import SEO from "gatsby-theme-rothenberger/src/@yumgmbh/gatsby-theme-yum-components/components/modules/Seo"

const VariantsTemplate = ({ data }) => {
  const entry = data.entry
  const group = data.group
  const line = data.line
  const area = data.area
  const sbPage = data.storyblokPage
  const sbPageContent =
    sbPage && sbPage.content ? JSON.parse(sbPage.content) : null
  const crossSellingVariants = data.crossSellingVariants.edges
  const deliveryScope =
    entry.properties.deliveryScope !== "undefined"
      ? entry.properties.deliveryScope
      : entry.properties.salesText

  let breadcrumbs = [
    { link: entry.rootPage.fullSlug, link_text: entry.rootPage.name },
    { link: area.fullSlug, link_text: area.name },
    { link: line.fullSlug, link_text: line.name },
    { link: group.fullSlug, link_text: group.name },
  ]

  const variants = data.variants.edges
  const description = entry.properties.description
  const features = entry.properties.features
  const techContent = JSON.parse(entry.properties.techContent)
  const baseData = JSON.parse(entry.properties.baseData)
  const documents = entry.properties.documents
  const safetyDatasheets = JSON.parse(entry.properties.safetyDatasheets)
  const crossSellingItems = crossSellingVariants?.map((v) => {
    return { label: v.node.name, link: v.node.fullSlug }
  })
  // const media = JSON.parse(entry.properties.media)

  const variantItems = variants
    .map((variant) => {
      return {
        active: entry.ID === variant.node.ID,
        slug: variant.node.fullSlug,
        headline: variant.node.name,
        text: variant.node.externalKey,
        image: variant.node.properties.mainImage,
        accessories:
          variant.node.properties.deliveryScope !== "undefined"
            ? variant.node.properties.deliveryScope
            : variant.node.properties.salesText,
      }
    })
    // active item is always the first item
    .sort((x, y) => {
      return x.active === y.active ? 0 : x.active ? -1 : 1
    })

  return (
    <Layout language={entry.language}>
      <SEO
        title={`${entry.name} | ${group.name}`}
        description={`${entry.properties?.description.replace(/\n/, "")}`}
        og_image={entry.properties?.mainImage}
        language={entry.language}
      />
      <ProductHeader
        block={{ headline: entry.name, _uid: entry.ID }}
        breadcrumbs={breadcrumbs}
      />

      <ProductTabsBlock
        entry={entry}
        description={description}
        variantItems={variantItems}
        features={features}
        deliveryScope={deliveryScope}
        crossSellingItems={crossSellingItems}
        documents={documents}
        safetyDatasheets={safetyDatasheets}
        techContent={techContent}
        baseData={baseData}
      />

      {sbPageContent && sbPageContent.body && sbPageContent.body.length > 0 && (
        <BodyBlocks body={sbPageContent.body} />
      )}
      <DividerBlock block={{}} />
      {variantItems?.length > 1 && (
        <VariantCardsBlock
          block={{
            variant_items: variantItems,
          }}
        />
      )}
    </Layout>
  )
}

export const query = graphql`
  query VariantQuery(
    $id: String
    $parentId: String!
    $locale: String
    $groupId: String
    $lineId: String
    $areaId: String
    $sku: String
    $crossSellingVariantIDs: [String]
  ) {
    entry: contentServVariant(ID: { eq: $id }, locale: { eq: $locale }) {
      name
      externalKey
      ID
      language
      rootPage {
        name
        fullSlug
      }
      properties {
        media
        description
        mainImage
        images
        features
        techContent
        baseData
        salesText
        deliveryScope
        productVideoFilenames
        productThrixtyFilenames
        documents {
          label
          fileName
          link
        }
        safetyDatasheets
      }
    }
    storyblokPage: storyblokEntry(tag_list: { in: [$sku] }) {
      content
    }
    group: contentServProductGroup(
      groupId: { eq: $groupId }
      locale: { eq: $locale }
    ) {
      name
      fullSlug
    }
    line: contentServProductLine(
      lineId: { eq: $lineId }
      locale: { eq: $locale }
    ) {
      name
      fullSlug
    }
    area: contentServProductArea(
      areaId: { eq: $areaId }
      locale: { eq: $locale }
    ) {
      name
      fullSlug
    }
    variants: allContentServVariant(
      filter: { parentId: { eq: $parentId }, locale: { eq: $locale } }
    ) {
      edges {
        node {
          name
          fullSlug
          ID
          parentId
          externalKey
          properties {
            mainImage
            salesText
            deliveryScope
          }
        }
      }
    }
    crossSellingVariants: allContentServVariant(
      filter: { ID: { in: $crossSellingVariantIDs }, locale: { eq: $locale } }
    ) {
      edges {
        node {
          name
          fullSlug
        }
      }
    }
  }
`

export default VariantsTemplate
